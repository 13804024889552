import { TipoDistribuidora } from 'config/tipoDistribuidoraEnum';
import { InfoClienteModel } from 'models';

/**
 * Metodo que se encarga de validar si un dato existe.
 * @param dato valor del dato a validar.
 * @returns true si es valido, false si es invalido.
 */
export const esDatoValido = (dato: any) => dato && dato !== undefined && dato !== null;

/**
 * Setea textos y url por distribuidora del cliente.
 */
export const setearDistribuidora = (infoCliente: InfoClienteModel) => {
  switch (infoCliente.codDistribuidora) {
    case TipoDistribuidora.SAESA:
      return { glosa: TipoDistribuidora.GLOSA_SAESA, url: process.env.REACT_APP_URL_HOME_SAESA! };
    case TipoDistribuidora.EDELAYSEN:
      return { glosa: TipoDistribuidora.GLOSA_EDELAYSEN, url: process.env.REACT_APP_URL_HOME_EDELAYSEN! };
    case TipoDistribuidora.FRONTEL:
      return { glosa: TipoDistribuidora.GLOSA_FRONTEL, url: process.env.REACT_APP_URL_HOME_FRONTEL! };
    case TipoDistribuidora.LUZ_OSORNO:
      return { glosa: TipoDistribuidora.GLOSA_LUZ_OSORNO, url: process.env.REACT_APP_URL_HOME_LUZOSORNO! };
    default:
      return { glosa: TipoDistribuidora.GLOSA_GRUPO_SAESA, url: process.env.REACT_APP_URL_GRUPO_SAESA! };
  }
};
