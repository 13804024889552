import axios from 'axios';

const mostrarLoading = () => {
  let contador: string | number | null = sessionStorage.getItem('loading');
  contador = parseInt(contador!, 10) + 1;
  sessionStorage.setItem('loading', contador.toString());

  const loading = document.getElementById('loading');
  return loading?.classList.add('backdrop--show');
};

const AuthInterceptor = () => {
  const apiKey = `${process.env.REACT_APP_HEADER_API_KEY}`;
  const publicIgnore = new RegExp(/\/public/g);
  const regexFeatureFlagIgnore = new RegExp(/\/featureFlag.json/g);
  axios.interceptors.request.use(request => {
    mostrarLoading();
    const token = localStorage.getItem('token');

    if (!regexFeatureFlagIgnore.test(request.url!)) {
      request.headers['x-api-key'] = apiKey;

      if (!publicIgnore.test(request.url!)) {
        if (!token || request.headers.Authorization) {
          request.headers.Authorization = request.headers.Authorization || null;
        } else {
          request.headers.Authorization = `Bearer ${token}`;
        }
      }
    }

    return request;
  });
};

export default AuthInterceptor;
