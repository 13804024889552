import { Hidden } from '@material-ui/core';
import { GoogleMap, KmlLayer, Marker, StandaloneSearchBox, useLoadScript } from '@react-google-maps/api';
import { FlechaVerticalIcon, Input, LupaIcon, Typography, StepperTracking } from '@saesa/ui-kit-front';
import { newResponseTracking } from 'utils/ejemploRespuestaServicioCortes';
import {
  obtenerElementoTimeFin,
  obtenerElementoTimeInicio,
  obtenerElementoTimeMedio,
  obtenerTextoInformativo,
  obtenerPantallaSigoConProblemas,
  obtenerTextoInferiorReportar,
} from 'helper/trackingCorte.helper';
import clsx from 'clsx';
import ModalPoligono from 'components/Modal/ModalPoligono';
import { QueryParams, TipoEstadoActualSecundarioTrackingCorteEnum, TipoEstadoActualTrackingCorteEnum, TipoTrackingCorteEnum } from 'config';
import { useFormik } from 'formik';
import { CoordenadasMapa, CoordenadasEmpresasModel } from 'models';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useState } from 'react';
import { TIPOCORTETITULO } from 'config/tiposCorteTitulo';
import { useTranslation } from 'react-i18next';
import DesconexionService from 'services/desconexionService';
import { EtapasModels } from 'models/EtapasModels';
import { TipoDistribuidora } from 'config/tipoDistribuidoraEnum';
import { TrackingCorteEstadosResponseModel } from 'services/models';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  actualizarAcordeonNotificacionTerAbierto,
  actualizarEstadoCaptchaV3,
  actualizarEstadoMensajeFlotanteCerrado,
  actualizarEstadoModalMobileAbierto,
  actualizarTokenCaptchaV3,
} from 'store';
import VentanaMobile from 'components/VentanaMobile/VentanaMobile';
import AnalyticsService from 'services/AnalyticsService';
import useStylesCortes from 'pages/Styles/VistasCorteStyles';
import { obtenerZoom } from 'utils/obtenerZoom';
import { milisegundosPorMinuto } from 'utils/constants';
import { EventosGtmSaesa } from 'utils/GtmEvents';
import { clock, flashSlash, repair } from 'assets/images';
import { TIPOCORTE } from 'config/tiposCorte';
import {
  esCorteImprevisto,
  esCorteImprevistoConArriboBrigada,
  esCorteImprevistoSinArriboBrigada,
  noEsCorteImprevisto,
} from 'utils/colorBallsUtils';
import { esFechaVencida } from 'utils/utilitario';
import { scrollInicialModal } from 'utils/trackingUtils';
import ModalSitioNoDisponible from 'components/ModalSitioNoDisponible/ModalSitioNoDisponible';
import { mostrarModalCookie } from 'utils/cookieUtils';
import CaptchaV3 from 'components/Captchas/CaptchaV3';
import Disclaimer from './Disclaimer/Disclaimer';
import ColorBallsImprevistoSinBrigada from './ColorBalls/ColorBallsImprevistoSinBrigada';
import ColorBallsImprevistoConBrigada from './ColorBalls/ColorBallsImprevistoConBrigada';
import DisclaimerTerVencido from './DisclaimerTerVencido/DisclaimerTerVencido';
import useStyles from './MapsStyles';

const Maps = ({
  className,
  height,
  maxHeight,
  width,
  zoom,
  mobile,
}: {
  className: string;
  height?: string;
  maxHeight: string;
  width?: string;
  zoom?: number;
  mobile?: boolean;
}) => {
  /**
   * Variable de estado para almacenar cache Control de kml
   */
  const [cacheControl, setCacheControl] = useState<any>();

  /**
   * Metodo encargado de setear el cache control
   */
  useEffect(() => {
    setCacheControl(new Date().getTime());
  }, []);

  /**
   * Constante que contiene la url del kml
   */
  const kmlUrl = `${process.env.REACT_APP_MAP_ASSETS_URL}/outage.kml`;
  /**
   * Constante que contiene la url del kml de cortes futuros
   */
  const kmlUrlFuturos = `${process.env.REACT_APP_MAP_ASSETS_URL}/cortes_futuros.kml`;

  /**
   * Constante que contiene el nombre del corte futuro
   */
  const nombreCorteFuturo = 'Corte: Futuro';

  /**
   * Disparador para el selector
   */
  const dispatch = useDispatch();

  /**
   * Función que genera un número según la fecha y hora actual.
   * Se genera para consultar al mapa y evitar el caché de Google Maps.
   * @returns retorna el valor como un número.
   */
  const rutaKmlCache = (kml: string) => {
    const concatenador = kml.toLocaleLowerCase().endsWith('kml') ? '?' : '&';
    return kml + concatenador + cacheControl;
  };

  /**
   *  Variable que almacena el número de caché generado.
   */
  const nuevaRutaKml = rutaKmlCache(kmlUrl);
  /**
   *  Variable que almacena el número de caché generado de cortes futuros.
   */
  const nuevaRutaKmlFuturos = rutaKmlCache(kmlUrlFuturos);

  /**
   * Modelo de pasos stepper
   */
  const modeloPasos: EtapasModels[] = [];
  /**
   * Variable que maneja query params
   */
  const query = QueryParams();
  /**
   * valor de cliente obtenido por query params
   */
  const queryNroCliente = query.get('cliente') || '';

  /**
   * valor de empresa obtenido por query params
   */
  const queryNombreEmpresa = query.get('empresa') || '';

  /**
   * Función que agrega el token de captcha v3
   */
  const onVerify = useCallback(tokens => {
    dispatch(actualizarTokenCaptchaV3(tokens));
  }, []);

  /**
   * Obtiene el valor del pec
   */
  const modoPEC = useSelector((state: RootState) => state.desconexion.modoPEC);

  /**
   * Obtiene el valor del flag del estado modal no disponible
   */
  const modalNoDisponibleFlagVisible = useSelector((state: RootState) => state.desconexion.modalPaginaNoDisponible.activacionModal);

  /**
   * Obtiene el valor del flag del estado de notificación TER
   */
  const notificacionTerActivo = useSelector((state: RootState) => state.desconexion.flagCargadoNotificacionTer);

  /**
   * Obtiene el valor del flag del valor en minutos de la cookie para el modal no disponible
   */
  const valorMinutosModal: number = useSelector((state: RootState) => state.desconexion.modalPaginaNoDisponible.tiempoCookie);

  /**
   * Obtiene el valor del flag estado de carga del flag
   */
  const flagCargado: boolean = useSelector((state: RootState) => state.desconexion.flagCargado);

  /**
   * Obtiene el valor de habilitación del corte futuro
   */
  const cortesFuturosHabilitacion = useSelector((state: RootState) => state.desconexion.cortesFuturos);

  /**
   * Obtiene el valor del switch de cortes futuros
   */
  const switchCortesFuturos = useSelector((state: RootState) => state.desconexion.switchCortesFuturos);

  /**
   * Variable para opciones de google maps
   */
  const zoomControlOptionsPosition = 9;

  /**
   * Variable para opciones de google maps
   */
  const streetViewControlOptionsPosition = 0;

  /**
   * Variable para opciones de google maps
   */
  const mapTypeControlOptionsPosition = 3;

  /**
   * Inicializador de inputs de formulario
   */
  const initialValues: FormValues = {
    nroCliente: '',
    direccion: '',
  };

  let formik = useFormik({
    initialValues,
    onSubmit: () => {
      // Inicialización formik
    },
  });
  /**
   * Variable que maneja el uso de estilos.
   */
  const styles = useStyles();
  const stylesCortes = useStylesCortes();

  /**
   * Uso de las pildoras de notificaciones
   */
  const { enqueueSnackbar } = useSnackbar();

  /**
   * Uso del traductor
   */
  const { t } = useTranslation();

  /**
   * id marker busqueda
   */
  const ID_MARKER_BUSQUEDA = 'pinBusqueda';

  /**
   *  Valores de formulario
   */
  interface FormValues {
    nroCliente: string;
    direccion: string;
  }

  /**
   * Arreglo que contiene modelo de coordenadas a utilizar en mapa de google maps.
   */
  let dataMapa: CoordenadasMapa[] = [];

  /**
   * useState que actualiza data de busquedas realizadas
   */
  const [infoBusqueda, setInfoBusqueda] = useState<any>();

  /**
   * UseState que controla el estado del modal de no disponible
   */
  const [modalNoDisponible, setModalNoDisponible] = useState<boolean>(false);

  /**
   * useState que actualiza data de busquedas realizadas
   */
  const [newZoom, setNewZoom] = useState<number>();

  /**
   * Constante que contiene las coordenadas iniciales de la distribuidora Frontel
   */
  const coordenadasFrontel = { lat: -37.7984415592484, lng: -72.70651503708764 };

  /**
   * Constante que contiene las coordenadas iniciales de la distribuidora Edelaysen
   */
  const coordenadasEdelaysen = { lat: -45.572728092875316, lng: -72.0688308325724 };

  /**
   * Constante que contiene las coordenadas iniciales de la distribuidora Osorno
   */
  const coordenadasOsorno = { lat: -40.57118, lng: -73.1387 };

  /**
   *
   * @param compania string obtiene de la querypraams la compañia a la que pertenece el cliente
   * @returns coordenadas de la compañia
   */
  function obtenerCoordenadas(compania: string): CoordenadasEmpresasModel {
    switch (compania) {
      case TipoDistribuidora.FRONTEL:
        return coordenadasFrontel;
      case TipoDistribuidora.EDELAYSEN:
        return coordenadasEdelaysen;
      case TipoDistribuidora.LUZ_OSORNO:
        return coordenadasOsorno;
      default:
        return coordenadasOsorno;
    }
  }

  /**
   * inicializacion de arreglo de lugares que guarda coordenadas a marcar en el mapa
   */
  let lugares: CoordenadasMapa[] = [
    {
      id: 'CentralSaesaOsorno',
      pos: obtenerCoordenadas(queryNombreEmpresa),
    },
  ];

  /**
   * useState que actualiza data de marker al realizar busquedas
   */
  const [marker, setMarker] = useState<CoordenadasMapa[]>(lugares);

  /**
   * Use state
   */
  const [mostrarInput, setMostrarInput] = useState(false);

  /**
   * Use state de las coordenadas del mapa.
   */
  const [coordenadas, setCoordenadas] = useState(dataMapa);

  /**
   * useState tipo booleano para mostrar modal
   */
  const [mostrarModal, setMostrarModal] = useState(false);

  /**
   * useState tipo booleano para mostrar buscador
   */
  const [mostrarBuscador, setMostrarBuscador] = useState(true);

  /**
   * useState tipo arreglo de strings de reposicion estimada sin tracking
   */
  const [dataSinTracking, setDataSinTracking] = useState();

  /**
   * useState tipo arreglo de strings de reposicion estimada sin tracking
   */
  const [titulo, setTitulo] = useState('inesperado');

  /**
   * useState tipo string para capturar el tipo de corte
   */
  const [respuestaServicio, setRespuestaServicio] = useState<TrackingCorteEstadosResponseModel>(newResponseTracking);

  /**
   * Use state para mostrar modal con elementos de tracking
   */
  const [modalTracking, setModalTracking] = useState(false);

  /**
   * Use state para mostrar modal con elementos de tracking
   */
  const [servicioCargado, setServicioCargado] = useState(false);

  /**
   * Use state para mostrar modal de mobile
   */
  const [ventanaMobileState, setVentanaMobileState] = useState(false);

  /**
   * Use state para mostrar modal de mobile
   */
  const [activadorMobile, setActivadorMobile] = useState(false);

  /**
   * Use state para mostrar si el corte es porgramado futuro
   */
  const [corteProgramado, setCorteProgramado] = useState(false);

  /**
   * Use state de fecha de inicio de corte de corte o el primer item
   */
  const [primeraData, setPrimeraData] = useState('');

  /**
   * Use state de fecha de inicio de corte de corte o el primer item
   */
  const [primeraDataSinTracking, setPrimeraDataSinTracking] = useState();

  /**
   * Use state de fecha de inicio de corte de corte o el segundo item
   */
  const [segundaData, setSegundaData] = useState('');
  /**
   * Use state de fecha de inicio de corte de corte o el segundo item
   */
  const [segundaDataSinTracking, setSegundaDataSinTracking] = useState();
  /**
   * Modelo de pasos stepper
   */
  modeloPasos.push(obtenerElementoTimeInicio(respuestaServicio, t)); // inicio
  modeloPasos.push(obtenerElementoTimeMedio(respuestaServicio, t)); // medio
  modeloPasos.push(obtenerElementoTimeFin(respuestaServicio, t)); // fin

  /**
   * Componente texto bajada stepper
   */
  const vistaTextoInformativo = obtenerTextoInformativo(respuestaServicio, t, stylesCortes.textoInformativo);

  /**
   * Componente "continuo con dramas de servicio" solo aplica en estado *normalized*
   */
  const vistaProblemasServicio = obtenerPantallaSigoConProblemas(
    respuestaServicio,
    t,
    notificacionTerActivo,
    stylesCortes.avisoPago,
    corteProgramado
  );

  /**
   * Componente texto bajada stepper
   */
  const vistaTextoInferiorReportar = obtenerTextoInferiorReportar(respuestaServicio, t, corteProgramado);

  /**
   * Constante que contiene el titulo que no se debe mostrar cuando no existe tracking
   */
  const tituloListaNegra = 'Caso';

  /**
   * Constante que contiene el titulo que no se debe mostrar
   */
  const tituloClientesListaNegra = 'Clientes afectados';

  /**
   * Constante que contiene el titulo que no se debe mostrar
   */
  const tituloRestauracionListaNegra = 'Restauración estimada';

  /**
   * Constante que contiene el tipo de titulo que no se debe mostar para el corte sin tracking en vista reducida
   */
  const tipoCorteFuturo = 'Futuro ';

  /**
   * Constante que contiene el titulo que no se debe mostrar
   */
  const tituloFechaFinListaNegra = ' Fecha fin';
  /**
   * Constante que contiene el titulo que no se debe mostrar
   */
  const tituloFechaInicioListaNegra = ' Fecha inicio';

  /**
   * Zoom del mapa para mobile para busqueda de numero de cliente o dirección
   */
  const zoomMobile = 14;

  /**
   * Zoom del mapa para escritorio para busqueda de numero de cliente o dirección
   */
  const zoomEscritorio = 13;

  /**
   * Setea datos en el mapa al cargar
   */
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_API_KEY || '',
    libraries: ['places', 'drawing', 'visualization'],
  });

  /**
   * Metodo inicializa los puntos de coordenadas por defecto en el mapa.
   */
  const inicializarDataMapa = () => {
    formik.setFieldValue('nroCliente', queryNroCliente);
    dataMapa = lugares;
    setCoordenadas(dataMapa);
    setNewZoom(zoom);

    // Se envía información a tag manager con carga incial del mapa
    AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.acceso);
  };

  /**
   * Metodo que actualiza la posicion del marcador
   * @param lat latitud de coordenadas de busqueda
   * @param lng longitud de coordenadas de busqueda
   */
  const actualizarPosicionMarcador = (lat: number, lng: number) => {
    const dataPinDireccion: CoordenadasMapa[] = [
      {
        id: ID_MARKER_BUSQUEDA,
        pos: {
          lat,
          lng,
        },
      },
    ];
    lugares = [];
    setMarker(dataPinDireccion);
    setNewZoom(mobile ? zoomMobile : zoomEscritorio);
  };

  /**
   * Metodo que realiza busqueda por numero de cliente
   * @param nroCliente numero de cliente
   */
  const consultarNroCliente = (nroCliente: string) => {
    DesconexionService.busquedaNroCliente(nroCliente)
      .then(response => {
        const dataNroCliente: CoordenadasMapa[] = [
          {
            id: 'pinBusqueda',
            pos: {
              lat: Number(response.latitud),
              lng: Number(response.longitud),
            },
          },
        ];
        setCoordenadas(dataNroCliente);
        actualizarPosicionMarcador(Number(response.latitud), Number(response.longitud));
        document.getElementById('nroCliente')!.blur();

        // Se envía información a tag manager con busqueda por numero de cliente
        AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.busqueda.busquedaServicio);
      })
      .catch((error: any) => {
        // Se envía información a tag manager con busqueda por numero de cliente con error
        AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.busqueda.busquedaServicioError);

        enqueueSnackbar(t(`errors:${error?.response?.data?.mensaje}`), {
          variant: 'error',
        });
      });
  };

  /**
   * Metodo que se gatilla al enviar formulario
   * @param values Valor del formulario
   */
  const onFormSubmit = (values: FormValues) => {
    consultarNroCliente(values.nroCliente);
  };

  /**
   * Constante que contiene el tiempo para mostrar el modal
   */
  const tiempoMostrarModal = valorMinutosModal * milisegundosPorMinuto;

  /**
   * Use effect que se gatilla al inicializar componente
   */
  useEffect(() => {
    inicializarDataMapa();

    if (queryNroCliente) {
      consultarNroCliente(queryNroCliente);
    }
  }, []);

  /**
   * UseEffect que maneja el estado de la cookie del modal
   */
  useEffect(() => {
    if (valorMinutosModal > 0 && modalNoDisponibleFlagVisible) {
      setModalNoDisponible(mostrarModalCookie('cookieModalMapa', tiempoMostrarModal));
    }
  }, [flagCargado]);

  /**
   * Constante que contiene el estado del modal
   */
  const modalActivo = modalNoDisponible && modalNoDisponibleFlagVisible;

  /**
   * inicializacion de formik
   */
  formik = useFormik({
    initialValues,
    onSubmit: onFormSubmit,
    validationSchema: Yup.object({
      nroCliente: Yup.string()
        .required(t('desconexion:paginas.mapaCortes.inputs.errors.inputNroCliente'))
        .soloNumero(t('desconexion:paginas.mapaCortes.inputs.errors.soloNumero'))
        .max(20, t('desconexion:paginas.mapaCortes.inputs.errors.max')),
    }),
  });

  /**
   * Metodo que carga funcionalidades del mapa
   * @param ref referencia
   */
  const onLoad = (ref: any) => {
    setInfoBusqueda(ref);
  };

  /**
   * Metodo que actualzia coordenadas de las busquedas por direcciones en el mapa
   */
  const realizarBusquedaPorDireccion = () => {
    if (infoBusqueda.getPlaces()[0] === undefined) {
      // Se envía información a tag manager con busqueda por dirección con error
      AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.busqueda.busquedaDireccionError);

      enqueueSnackbar(t('desconexion:paginas.mapaCortes.inputs.errors.direccionMapaInvalida'), {
        variant: 'error',
      });
    } else {
      actualizarPosicionMarcador(infoBusqueda.getPlaces()[0].geometry.location.lat(), infoBusqueda.getPlaces()[0].geometry.location.lng());
      const dataDireccion: CoordenadasMapa[] = [
        {
          id: ID_MARKER_BUSQUEDA,
          pos: {
            lat: infoBusqueda.getPlaces()[0].geometry.location.lat(),
            lng: infoBusqueda.getPlaces()[0].geometry.location.lng(),
          },
        },
      ];
      setCoordenadas(dataDireccion);
      formik.setFieldValue('direccion', infoBusqueda.getPlaces()[0].name);
      document.getElementById('direccion')!.blur();
      // Se envía información a tag manager con busqueda por dirección
      AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.busqueda.busquedaDireccion);
    }
  };

  /**
   * Obtiene la información del poligono
   * @param event es el evento que se la pasa del click
   */
  const obtenerInformacion = (event: any) => {
    const data = event.split('<div>')[1].split('<br>');
    data[data.length - 1] = data[data.length - 1].replace('</div></div>', '');

    const objetoData = data
      .map((datas: string) => ({ titulo: datas.split(':')[0], texto: datas.split(': ')[1] }))
      .filter((datas: any) => datas.titulo !== tituloListaNegra);

    /**
     * Se realiza cambios en el titulo del poligono del kml
     */
    const arregloModificado = objetoData.map((obj: any) => {
      let nuevoTitulo;
      if (obj.titulo === tituloClientesListaNegra) {
        nuevoTitulo = t('desconexion:paginas.tracking.corte.listaBlancaKML.clientesAfectados');
      } else if (obj.titulo === tituloRestauracionListaNegra) {
        nuevoTitulo = t('desconexion:paginas.tracking.corte.listaBlancaKML.reposicionEstimada');
      } else {
        nuevoTitulo = obj.titulo;
      }
      return { ...obj, titulo: nuevoTitulo };
    });

    if (arregloModificado[0].texto === tipoCorteFuturo) {
      const arregloModificadoFuturo = objetoData.map((obj: any) => {
        let nuevoTitulo;
        if (obj.titulo === tituloFechaInicioListaNegra) {
          nuevoTitulo = t('desconexion:paginas.tracking.corte.listaBlancaKML.fechaInicio');
        } else if (obj.titulo === tituloFechaFinListaNegra) {
          nuevoTitulo = t('desconexion:paginas.tracking.corte.listaBlancaKML.reposicionEstimada');
        } else {
          nuevoTitulo = obj.titulo;
        }
        return { ...obj, titulo: nuevoTitulo };
      });

      setPrimeraDataSinTracking(arregloModificadoFuturo[1]);
      setSegundaDataSinTracking(arregloModificadoFuturo[2]);
      const objetoFuturo = arregloModificadoFuturo.filter((datas: any) => datas.texto !== tipoCorteFuturo);
      setDataSinTracking(objetoFuturo);
    } else {
      setPrimeraDataSinTracking(arregloModificado[0]);
      setSegundaDataSinTracking(arregloModificado[1]);
      setDataSinTracking(arregloModificado);
    }
  };

  /**
   * Metodo encargado de obtener y formatear fecha TER desde KML
   * @param event del kml
   * @returns fecha formeateada
   */
  const obtenerFechaTER = (event: any) => {
    try {
      const data = event.split('<div>')[1].split('<br>');
      data[data.length - 1] = data[data.length - 1].replace('</div></div>', '');
      const objetoData = data
        .map((datas: string) => ({ titulo: datas.split(':')[0], texto: datas.split(': ')[1] }))
        .filter((datas: any) => datas.titulo !== tituloListaNegra);

      const fechaTER = objetoData.filter((dataCorte: any) => dataCorte.titulo === 'Restauración estimada')[0].texto;

      const [day, month, yearHour] = fechaTER.split('/');
      const [year, time] = yearHour.split(' ');

      return `${year}-${month}-${day}T${time}`;
    } catch (e) {
      return undefined;
    }
  };

  /**
   *Función que se encarga de obtener el titulo para el tipo de corte
   * @param tipo titulo de corte proveniente del tracking
   */
  const tituloCorte = (tipo: string) => {
    switch (tipo) {
      case TIPOCORTETITULO.CORTE_INESPERADO:
        setTitulo(t('desconexion:paginas.mapaCortes.modalTracking.titulosModal.corteInesperado'));
        break;

      case TIPOCORTETITULO.CORTE_PROGRAMADO:
        setTitulo(t('desconexion:paginas.mapaCortes.modalTracking.titulosModal.corteProgramado'));
        break;

      case TIPOCORTETITULO.CORTE_FUTURO:
        setTitulo(t('desconexion:paginas.mapaCortes.modalTracking.titulosModal.corteFuturo'));
        break;

      default:
        setTitulo(t('desconexion:paginas.mapaCortes.modalTracking.titulosModal.corteSinTracking'));
        break;
    }
  };

  /**
   * Función para verificar el estado actual secundario en caso que sea null o "" se tomara como un error de la respuesta de tracking y se mostrara la data del kml
   * @param estadoActualSecundario estado actual secundario respuesta del servicio
   * @param tipo tipo de corte
   */
  function verificarEstadoActualSecundario(estadoActualSecundario: any, tipo: any) {
    if (
      !Object.values(TipoEstadoActualSecundarioTrackingCorteEnum).includes(estadoActualSecundario) &&
      tipo === TipoTrackingCorteEnum.IMPREVISTO
    ) {
      setModalTracking(false);
      setTitulo(t('desconexion:paginas.mapaCortes.modalTracking.titulosModal.corteSinTracking'));
    }
  }

  /**
   * Metodo de clic de los poligonos
   * Captura los datos del KML, activa el modal que muestra la información del corte
   * Ejecuta un servicio
   * @param event: nos trae datos del arhcivo KML entre los cuales esta el OA o numero del servicio
   */

  const onClickKml = (event: any) => {
    scrollInicialModal();
    obtenerInformacion(event.featureData.infoWindowHtml);
    dispatch(actualizarEstadoModalMobileAbierto({ modalMobileAbierto: true }));
    dispatch(actualizarEstadoMensajeFlotanteCerrado({ mensajeFlotanteCerrado: true }));

    AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.poligono.llamadaTracking);

    DesconexionService.obtenerRespuestaCorte(event.featureData.name)
      .then(response => {
        setRespuestaServicio(response);
        setCorteProgramado(event.featureData.description.includes(nombreCorteFuturo));
        setPrimeraData(
          response.fechaInicioCorte !== '' && response.fechaInicioCorte !== null
            ? response.fechaInicioCorte
            : t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar')
        );
        setSegundaData(
          response.fechaFinCorte !== '' && response.fechaFinCorte !== null
            ? response.fechaFinCorte
            : t('desconexion:paginas.tracking.corte.avisoPago.textos.sinDeterminar')
        );

        dispatch(actualizarEstadoCaptchaV3(true));
        dispatch(actualizarAcordeonNotificacionTerAbierto(false));

        const fechaTER = obtenerFechaTER(event.featureData.infoWindowHtml);
        if (
          fechaTER &&
          response.estadoActual !== TipoEstadoActualTrackingCorteEnum.NORMALIZED &&
          response.tipo === TipoTrackingCorteEnum.IMPREVISTO &&
          (response.fechaFinCorte === undefined || response.fechaFinCorte === null || response.fechaFinCorte === '')
        ) {
          const responseTER = { ...response, fechaFinCorte: fechaTER };
          setRespuestaServicio(responseTER);
          setSegundaData(fechaTER);
        }

        setModalTracking(true);
        setServicioCargado(true);
        tituloCorte(event.featureData.description.includes(nombreCorteFuturo) ? TIPOCORTETITULO.CORTE_FUTURO : response.tipo);
        setVentanaMobileState(true);

        // Se valida que el tracking tenga falla activa para el corte, en caso de tener falla pero no contar con el tipo de corte
        // se muestra la información desde el KML.
        if (response.estadoActual === TipoEstadoActualTrackingCorteEnum.SIN_FALLAS || response.tipo === '' || !response.tipo) {
          obtenerInformacion(event.featureData.infoWindowHtml);
          setModalTracking(false);
          setServicioCargado(true);
          setTitulo(t('desconexion:paginas.mapaCortes.modalTracking.titulosModal.corteSinTracking'));
        } else {
          AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.poligono.cargaTracking);
        }

        verificarEstadoActualSecundario(response.estadoActualSecundario, response.tipo);
      })
      .catch(() => {
        setModalTracking(false);
        setVentanaMobileState(true);
        setCorteProgramado(event.featureData.description.includes(nombreCorteFuturo));
        setServicioCargado(true);
        setTitulo(t('desconexion:paginas.mapaCortes.modalTracking.titulosModal.corteSinTracking'));
      });

    setMostrarModal(true);
    setMostrarBuscador(false);
  };

  /**
   * Metodo que se encarga de mostrar input correspondiente en mobile
   */
  const mostrarInputCorrespondiente = () => {
    setMostrarInput(!mostrarInput);
    formik.resetForm();
  };

  /**
   * Función para cerrar modal de no disponible
   */
  const cerrarModalNoDisponible = () => {
    setModalNoDisponible(false);
  };

  const desplegarBuscador: any = () =>
    mostrarBuscador ? (
      <section className={styles.buscadorCnt} id="buscadorCnt" data-testid="buscador-Cnt">
        <form className={styles.formCnt} onSubmit={formik.handleSubmit} data-testid="buscarNroCliente">
          <Input
            className={clsx(styles.inputNroServicio, {
              [styles.esconderElemento]: !mostrarInput,
              [styles.inputHasError]: formik.errors.nroCliente,
            })}
            type="text"
            dataTestId="nroCliente"
            errors={formik.errors.nroCliente}
            touched={formik.touched.nroCliente}
            name="nroCliente"
            value={formik.values.nroCliente}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder={t('desconexion:paginas.mapaCortes.inputs.placeHolders.inputNroCliente')}
            startAdornment={
              <button className={styles.btnIconDireccion} aria-label="boton-mostrar-input" type="submit" data-testid="submit">
                <FlechaVerticalIcon
                  className={clsx(styles.flechaIcon, {
                    [styles.flechaIconDisabled]: !formik.dirty || Boolean(formik.errors.nroCliente),
                  })}
                />
              </button>
            }
          />
        </form>

        <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={realizarBusquedaPorDireccion}>
          <div>
            <div
              className={clsx(styles.inputCnt, {
                [styles.esconderElemento]: mostrarInput,
              })}
            >
              <Input
                className={styles.inputDireccion}
                type="text"
                dataTestId="direccion"
                name="direccion"
                value={formik.values.direccion}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={t('desconexion:paginas.mapaCortes.inputs.placeHolders.inputDireccion')}
                startAdornment={<LupaIcon className={styles.lupaIcon} />}
              />
            </div>
            <Hidden only={['md', 'lg', 'xl']}>
              <Typography
                strong="semi-bold"
                variant="h2"
                className={clsx(styles.textoCambiar, {
                  [styles.esconderElemento]: !mostrarInput,
                })}
                onClick={mostrarInputCorrespondiente}
              >
                {t('desconexion:paginas.mapaCortes.texts.buscarPorDireccion')}
              </Typography>
              <Typography
                strong="semi-bold"
                variant="h2"
                className={clsx(styles.textoCambiar, {
                  [styles.esconderElemento]: mostrarInput,
                })}
                onClick={mostrarInputCorrespondiente}
              >
                {t('desconexion:paginas.mapaCortes.texts.buscarPorNroCliente')}
              </Typography>
            </Hidden>
          </div>
        </StandaloneSearchBox>
      </section>
    ) : (
      ''
    );

  /**
   * Funcion que renderiza la ventana mobile
   * @returns la ventana mobile
   */
  const ventanaMobileFuncionTracking = () =>
    ventanaMobileState && (
      <Hidden only={['md', 'lg', 'xl']}>
        <VentanaMobile
          titulo={titulo}
          setActivadorMobile={setActivadorMobile}
          setVentanaMobileState={setVentanaMobileState}
          modalTracking={modalTracking}
          setMostrarModal={setMostrarModal}
          setServicioCargado={setServicioCargado}
          setMostrarBuscador={setMostrarBuscador}
          primeraData={modalTracking ? primeraData : primeraDataSinTracking}
          segundaData={modalTracking ? segundaData : segundaDataSinTracking}
        />
      </Hidden>
    );

  /**
   * Funcion que despliega el modal cuando existe tracking
   * @returns
   */
  const desplegarModalTrackingComponente: any = () => (
    <ModalPoligono
      titulo={titulo}
      setTitulo={setTitulo}
      dataSinTracking=""
      setMostrarModal={setMostrarModal}
      setMostrarBuscador={setMostrarBuscador}
      setVentanaMobileState={setVentanaMobileState}
      activadorMobile={activadorMobile}
      estadoTracking={
        titulo !== TIPOCORTE.CORTE_IMPREVISTO ? (
          <StepperTracking
            classNamePrincipal={styles.principal}
            classNameCirculo={styles.circulo}
            classNameIconoCnt={styles.iconoCnt}
            classNameIcono={styles.icono}
            classNameTextos={styles.textos}
            modeloPasos={modeloPasos}
            mostrarStepperOcultosMobile={false}
            ocultarPrimeraLinea
            ocultarUltimaLinea
          />
        ) : (
          ''
        )
      }
      setServicioCargado={setServicioCargado}
    >
      {esCorteImprevisto(titulo) && <Disclaimer horaActualizacion={respuestaServicio.fechaActualizacion} />}

      {esCorteImprevistoSinArriboBrigada(titulo, respuestaServicio.fechaArriboBrigada, respuestaServicio.estadoActualSecundario) && (
        <ColorBallsImprevistoSinBrigada iconoInicio={flashSlash} iconoFinal={clock} data={respuestaServicio} />
      )}

      {esCorteImprevistoConArriboBrigada(titulo, respuestaServicio.fechaArriboBrigada, respuestaServicio.estadoActualSecundario) && (
        <ColorBallsImprevistoConBrigada iconoInicial={flashSlash} iconoMedio={repair} iconoFinal={clock} data={respuestaServicio} />
      )}
      {esCorteImprevisto(titulo) && esFechaVencida(respuestaServicio.fechaFinCorte) && <DisclaimerTerVencido />}

      {noEsCorteImprevisto(titulo) && vistaTextoInformativo}
      {vistaProblemasServicio}
      {noEsCorteImprevisto(titulo) && vistaTextoInferiorReportar}
    </ModalPoligono>
  );

  /**
   * Funcion que despliega el modal cuando no existe tracking
   * @returns
   */
  const desplegarModalComponente: any = () => (
    <ModalPoligono
      titulo={titulo}
      setTitulo={setTitulo}
      setMostrarModal={setMostrarModal}
      setMostrarBuscador={setMostrarBuscador}
      dataSinTracking={dataSinTracking}
      setDataSinTracking={setDataSinTracking}
      setServicioCargado={setServicioCargado}
      activadorMobile={activadorMobile}
      setVentanaMobileState={setVentanaMobileState}
    />
  );

  /**
   * Metodo encargado de mostrar modal con información de tracking.
   * @returns Modal del poligono
   */
  const desplegarModalTracking: any = () => (
    <>
      <Hidden only={['md', 'lg', 'xl']}>
        {mostrarModal && modalTracking && servicioCargado && activadorMobile && !ventanaMobileState
          ? desplegarModalTrackingComponente()
          : ''}
      </Hidden>

      <Hidden only={['sm', 'xs']}>{mostrarModal && modalTracking && servicioCargado ? desplegarModalTrackingComponente() : ''};</Hidden>
    </>
  );

  /**
   * Metodo encargado de desplegar modal desde KML
   * @returns Modal del poligono
   */
  const desplegarModal: any = () => (
    <>
      <Hidden only={['md', 'lg', 'xl']}>
        {mostrarModal && !modalTracking && servicioCargado && activadorMobile && !ventanaMobileState ? desplegarModalComponente() : ''}
      </Hidden>

      <Hidden only={['sm', 'xs']}>{mostrarModal && !modalTracking && servicioCargado ? desplegarModalComponente() : ''}</Hidden>
    </>
  );

  return (
    <section data-testid="map-container" className={className}>
      {isLoaded ? (
        <GoogleMap
          zoom={newZoom}
          center={coordenadas[0]?.pos}
          options={{
            zoomControl: !mobile,
            zoomControlOptions: {
              position: zoomControlOptionsPosition,
            },
            streetViewControl: !mobile,
            streetViewControlOptions: { position: streetViewControlOptionsPosition },
            mapTypeControl: false,
            mapTypeControlOptions: { position: mapTypeControlOptionsPosition },
            fullscreenControl: !mobile,
            gestureHandling: 'greedy',
            minZoom: obtenerZoom(mobile, modoPEC),
          }}
          mapContainerStyle={{
            height,
            width,
            maxHeight,
            position: 'absolute',
            overflow: 'hidden',
          }}
        >
          {marker.map((place: CoordenadasMapa) => (
            <Marker
              key={place.id}
              position={place.pos}
              onLoad={() => {
                // Obligatorio
              }}
            />
          ))}

          {desplegarBuscador()}

          {cortesFuturosHabilitacion && switchCortesFuturos && (
            <KmlLayer
              url={`${nuevaRutaKmlFuturos}`}
              options={{ preserveViewport: true, suppressInfoWindows: true, zIndex: 1 }}
              onClick={event => onClickKml(event)}
            />
          )}

          <KmlLayer
            url={`${nuevaRutaKml}`}
            options={{ preserveViewport: true, suppressInfoWindows: true, zIndex: 2 }}
            onClick={event => onClickKml(event)}
          />

          {ventanaMobileFuncionTracking()}
          {desplegarModalTracking()}

          {desplegarModal()}
        </GoogleMap>
      ) : null}

      {modalActivo && <ModalSitioNoDisponible cerrarModal={cerrarModalNoDisponible} />}

      <CaptchaV3 onVerify={onVerify} />
    </section>
  );
};
Maps.defaultProps = {
  height: '80vh',
  width: '100vh',
  zoom: 1,
  mobile: false,
};
export default Maps;
