export const GRUPOSAESA_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/grupo-saesa-logo.svg`
export const SAESA_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/saesa-logo.svg`
export const FRONTEL_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/frontel-logo.svg`
export const EDELAYSEN_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/edelaysen-logo.svg`;
export const LUZOSORNO_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/luz-osorno-logo.svg`;
export { default as tooltipServicio } from './tooltip-numero-servicio.png';
export { default as iconoReportarCorteOK } from './icono-reportar-ok.svg';
export { default as iconoReportarCorteCortado } from './icono-reportar-cortado.svg';
export { default as iconoReportarCorteComercial } from './icono-reportar-comercial.svg';
export { default as iconoRedireccion } from './icono-redireccion.svg';
export { default as iconoRedireccionMorado } from './icono-redireccion-morado.svg';
export { default as iconoVolver } from './icono-volver.svg';
export { default as iconoInformacion } from './icono-informacion.svg';
export { default as iconoMapa } from './icono-mapa.svg';
export { default as flashSlash } from './flash-slash.svg';
export { default as clock } from './clock.svg';
export { default as alerta } from './alerta.svg';
export { default as repair } from './repair.svg';
export { default as alertaNaranja } from './alert-orange.svg';
export { default as CLOSE_CIRCLE } from './close-circle.svg';
export { default as ARROW_UP } from './arrow-up.svg';
export { default as ARROW_DOWN } from './arrow-down.svg';
