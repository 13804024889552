import { createStyles, makeStyles, Theme } from '@material-ui/core';

const AcordeonNotificacionTerStyles = makeStyles((theme: Theme) =>
  createStyles({
    contenedorAcordeon: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      boxShadow: ' 0px 1px 8px rgba(9, 39, 84, 0.14)',
      borderRadius: '5px',
    },
    contenedorInterno: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px 20px',
      background: 'var(--blanco)',
      borderRadius: '5px',
      gap: '4px',
    },
    titulo: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--gris-l3)',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px 20px 24px',
      gap: '32px',
      background: 'var(--blanco)',
      borderRadius: '0px 0px 5px 5px',
    },
    subtitulo: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#060709',
      [theme.breakpoints.down('sm')]: {},
    },
    internoContenedor: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginBottom: '-16px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '-24px',
      },
    },

    input: {
      display: 'block',
      width: '100%',
      padding: '8px',
      marginBottom: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },

    botonAcordeon: {
      padding: '0px',
      color: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
      },
    },

    borderAcordeonAbierto: {
      borderBottom: '1px solid var(--gris-l93)',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px',
    },

    inputNumero: {
      width: '100%',
      height: '45px',
      backgroundColor: 'var(--blanco)',
      [theme.breakpoints.down(960)]: {
        width: '100%',
      },
    },
    inputRoot: {
      display: 'flex',
      alignItems: 'center',
      height: '45px',
    },
    inputInput: {
      paddingLeft: '16px',
      [theme.breakpoints.down(960)]: {
        paddingLeft: '14px',
      },
    },
    contenedorCaptchaV2: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      alignItems: 'flex-start',
      flexWrap: 'wrap',
      alignContent: 'center',
      marginTop: '7px',
      [theme.breakpoints.down(960)]: {
        marginTop: '12px',
        alignItems: 'center',
      },
    },

    textoCaptchaV2: {
      fontFamily: 'Neutrif Studio',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: 'var(--rojo-l30)',
      textAlign: 'center',
    },
    recaptchaContainer: {
      transform: 'scale(0.86)',
      transformOrigin: '0 0',
      width: '254px',
      [theme.breakpoints.down('sm')]: {},
    },
    ocultarCaptcha: {
      height: '0',
      overflow: 'hidden',
    },
  })
);

export default AcordeonNotificacionTerStyles;
