import { useEffect, useRef, useState } from 'react';
import { Button, IconButton, Typography, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import { ARROW_UP, ARROW_DOWN } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actualizarAcordeonNotificacionTerAbierto, actualizarEstadoCaptchaV3, RootState } from 'store';
import ReCAPTCHA from 'react-google-recaptcha';
import * as Yup from 'yup';
import AnalyticsService from 'services/AnalyticsService';
import { EventosGtmSaesa } from 'utils/GtmEvents';
import './Selector.css';
import useStyles from './AcordeonNotificacionTerStyles';

const AcordeonNotificacionTer = () => {
  /**
   * Hook de traducción de textos
   */
  const { t } = useTranslation();

  /**
   * Hook de estilos
   */
  const styles = useStyles();

  /**
   * Disparador para el selector
   */
  const dispatch = useDispatch();

  /**
   * UseRef de recaptcha
   */
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const googleCaptchaKeyV2 = process.env.REACT_APP_CAPTCHA_KEY_V2 || '';

  /**
   * Obtiene el valor del estado del captcha v3
   */
  const estadoCaptchaV3 = useSelector((state: RootState) => state.desconexion.captchaV3Estado);

  /**
   * Obtiene el valor del token de captcha v3
   */
  const tokenCaptchaV3 = useSelector((state: RootState) => state.desconexion.tokenCaptchaV3);

  /**
   * Obtiene el estado de abierto del acordeon
   */
  const estadoAcordeonAbierto = useSelector((state: RootState) => state.desconexion.acordeonNotificacionTerAbierto);

  /**
   * Valores iniciales de formulario
   */
  const initialValues = {
    email: '',
  };

  /**
   * Valor de formik
   */
  /* eslint-disable prefer-const */
  let formik: any;

  /**
   *  UseState que contiene token generado por el captcha V2
   */
  const [tokenCaptchaV2, setTokenCaptchaV2] = useState<any>('');

  /**
   *  UseState que contiene el validador del boton enviar
   */
  const [validarBoton, setValidarBoton] = useState(true);

  /**
   * Funcion que controla el abrir y cerrar acordeon
   */
  const handleAcordeon = () => {
    dispatch(actualizarAcordeonNotificacionTerAbierto(!estadoAcordeonAbierto));
  };

  /**
   * Controlador de disparador en el boton enviar correo
   */
  const onSubmit = () => {
    if (estadoCaptchaV3) {
      dispatch(actualizarEstadoCaptchaV3(false));
    }
    AnalyticsService.enviarTag(EventosGtmSaesa.mapaDesconexiones.poligono.suscripcionCortes);
  };

  /**
   * Validaciones de errores del formulario
   */
  formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Yup.object({
      email: Yup.string().required(t('errors:enviarNotificacionTer.emailRequerido')).email(t('errors:enviarNotificacionTer.email')),
    }),
  });

  /**
   * Función que controla el estado del input email
   * @param event de clic
   */
  const onHandleBlur = (event: any) => {
    if (event.nativeEvent.target.value.length > 0) {
      const email = event.nativeEvent.target.value;
      const newEvent = { ...event };
      formik.values.emails = email;
      formik.handleBlur(newEvent);
    } else {
      formik.handleBlur(event);
    }
  };

  /**
   * Función controladora de la expiración del token del captcha
   */
  const handleExpired = () => {
    setTimeout(() => {
      window.location.reload();
    }, 4);
  };

  /**
   * UseEffect inicializador de formik
   */
  useEffect(() => {
    // Restablecer los campos tocados del formulario
    formik.setTouched({
      email: false,
    });

    formik.resetForm({
      values: {
        email: '',
      },
    });
  }, []);

  /**
   * UseEffect inicializador de formik
   */
  useEffect(() => {
    formik.values.email = '';
  }, [estadoAcordeonAbierto]);

  /**
   * UseEffect que valida el estado del boton de enviar
   */
  useEffect(() => {
    if (!formik.isValid) {
      setValidarBoton(true);
    } else if (tokenCaptchaV2 === '' && !tokenCaptchaV3) {
      setValidarBoton(true);
    } else if (!estadoCaptchaV3 && tokenCaptchaV2 === '') {
      setValidarBoton(true);
    } else if (formik.values.email === '') {
      setValidarBoton(true);
    } else {
      setValidarBoton(false);
    }
  }, [formik, tokenCaptchaV2, tokenCaptchaV3, estadoAcordeonAbierto]);

  return (
    <div className={styles.contenedorAcordeon}>
      <div className={`${styles.contenedorInterno} ${estadoAcordeonAbierto && styles.borderAcordeonAbierto}`}>
        <Typography className={styles.titulo}>{t('desconexion:paginas.mapaCortes.notificacionTer.titulo')}</Typography>
        <IconButton className={styles.botonAcordeon} aria-label="cerrar" onClick={handleAcordeon} data-testid="acordion-boton">
          {estadoAcordeonAbierto ? <img src={ARROW_UP} alt="cerrar" /> : <img src={ARROW_DOWN} alt="abrir" />}
        </IconButton>
      </div>
      {estadoAcordeonAbierto && (
        <div className={styles.content}>
          <div className={styles.internoContenedor}>
            <Typography className={styles.subtitulo}>{t('desconexion:paginas.mapaCortes.notificacionTer.subtitulo')}</Typography>
            <TextField
              id="outlined-basic"
              className={styles.inputNumero}
              name="email"
              value={formik.values.email}
              label={t('desconexion:paginas.mapaCortes.notificacionTer.input')}
              variant="outlined"
              type="text"
              onChange={formik.handleChange}
              inputProps={{ 'data-testid': 'email' }}
              onBlur={onHandleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                classes: {
                  root: styles.inputRoot,
                  input: styles.inputInput,
                },
              }}
            />

            <div className={`${styles.contenedorCaptchaV2} ${estadoCaptchaV3 && styles.ocultarCaptcha}`}>
              <Typography className={styles.textoCaptchaV2}>{t('desconexion:paginas.mapaCortes.notificacionTer.captcha.texto')}</Typography>
              <div className={styles.recaptchaContainer}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  data-testid="recaptcha"
                  sitekey={googleCaptchaKeyV2}
                  onExpired={handleExpired}
                  onChange={value => {
                    setTokenCaptchaV2(value);
                  }}
                />
              </div>
            </div>
          </div>

          <Button type="button" variant="contained" color="primary" onClick={onSubmit} data-testid="enviar-email" disabled={validarBoton}>
            {t('desconexion:paginas.mapaCortes.notificacionTer.boton')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AcordeonNotificacionTer;
