import { Hidden } from '@material-ui/core';
import { Header, Sidemenu } from 'components';
import { QueryParams } from 'config';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { actualizarLogo } from 'store';
import { obtenerLogo } from 'utils';
import { esDatoValido } from 'utils/desconexionUtils';
import { CONTACTO, GRUPOSAESA, HERRAMIENTAS, INDICADORES, INVERSIONISTA, PATH_ESTOY_SIN_LUZ, PATH_MAPA_IFRAME, QUEINESOMOS } from './constants';
import Routes from './navigation/RouterConfig';
import useStyles from './RootStyles';

const Root = ({ path }: { path: string }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  /**
   * translate
   */
  const { t } = useTranslation();

  /**
   * Variable que maneja query params
   */
  const query = QueryParams();
  /**
   * valor de empresa obtenido por query params
   */
  const tipoEmpresa = query.get('empresa');

  /**
   * Metodo que hace llamado de servicio para validar numero de cliente
   */
  const validarNroCliente = () => {
    let actualizarLogoBody = {
      urlLogo: obtenerLogo(null),
    };
    if (esDatoValido(tipoEmpresa)) {
      actualizarLogoBody = {
        urlLogo: obtenerLogo(tipoEmpresa),
      };
    }
    dispatch(actualizarLogo(actualizarLogoBody));
  };

  /**
   * Arreglo que contiene menu de navegacion de menu tope del header
   */
  const menuNavegacion = [
    {
      id: 0,
      option: t('desconexion:paginas.mapaCortes.home.menu.grupoSaesa'),
      url: GRUPOSAESA,
    },
    {
      id: 1,
      option: t('desconexion:paginas.mapaCortes.home.menu.quienesSomos'),
      url: QUEINESOMOS,
    },
    {
      id: 2,
      option: t('desconexion:paginas.mapaCortes.home.menu.inversionistas'),
      url: INVERSIONISTA,
    },
    {
      id: 3,
      option: t('desconexion:paginas.mapaCortes.home.menu.indicadores'),
      url: INDICADORES,
    },
    {
      id: 4,
      option: t('desconexion:paginas.mapaCortes.home.menu.herramientas'),
      url: HERRAMIENTAS,
    },
    {
      id: 5,
      option: t('desconexion:paginas.mapaCortes.home.menu.contacto'),
      url: CONTACTO,
    },
  ];

  useEffect(() => {
    validarNroCliente();
  }, []);

  return (
    <section className={styles.desconexion}>
      {window.location.pathname !== PATH_ESTOY_SIN_LUZ && window.location.pathname !== PATH_MAPA_IFRAME && (
        // Se excluye el header en el flujo de Reportar Corte
        <>
          <section className={styles.lineaTope}>
            <ul className={styles.menuRoot}>
              {menuNavegacion.map(item => (
                <li className={styles.item}>
                  <a className={styles.link} href={item.url} target="_blank" rel="noopener noreferrer">
                    {' '}
                    {item.option}
                  </a>
                </li>
              ))}
            </ul>
          </section>
          <Hidden only={['md', 'lg', 'xl']}>
            <Sidemenu path={path} />
          </Hidden>
          <Header className={styles.header} />
        </>
      )}
      <section className={styles.content}>
        <Routes path={path} />
      </section>
    </section>
  );
};

export default Root;
