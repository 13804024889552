/**
 * Constante que contiene la zona horaria de Chile
 */
export const zonaHoraria = 'America/Santiago';

/**
 * Constante que contiene el tipo de idioma de la hora en español de chile
 */
export const LOCALE_ES_CL = 'es-CL';

/**
 * Constante que contiene el tipo de idioma de la hora en ingles de Estados unidos
 */
export const LOCALE_EN_US = 'en-US';

/**
 * Constante que coneitne el Tipo de formato para la hora
 */
export const tipoFormateo = 'M-d-yyyy, HH:mm:ss';

/**
 * Constante que contiene el valor de cuantos milisegundos existen por cada minuto
 */
export const milisegundosPorMinuto = 60000;
